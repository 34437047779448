import React, { Component } from 'react';
import './Reviews.css'

class Reviews extends Component {
  render() {
        return (
            <div className='rew-hzchtoyablattakzaebalsyanahuy' >
              <h1>Отзывы</h1>
              <div className='rew-kogdajeetozaconchitsyablat'><iframe src="https://yandex.ru/maps-reviews-widget/121516923860?comments"></iframe><a href="https://yandex.ru/maps/org/tinto/121516923860/" target="_blank" >Tinto на карте Москвы — Яндекс Карты</a></div>
            </div>
        );
    }
}

export default Reviews;
